.node {
    border: 0.5px solid grey;
    box-sizing: border-box;
    margin: 0 -1px -1px 0;
    user-select: none;
    height: 20px;
    width: 20px;
}

.start {
    background-color: green;
}

.finish {
    background-color: red;
}

.visited {
    animation: visitedAnimation 1.5s ease-out forwards;
}

.wall {
    animation: wallAnimation 0.5s ease-out forwards;
}

.shortest {
    animation: shortestAnimation 1s ease-out forwards;
}

@keyframes visitedAnimation {
    from {
        background-color: white;
    }
    33% {
        background-color: aquamarine;
    }
    to {
        background-color: skyblue;
    }
}

@keyframes shortestAnimation {
    from {
        background-color: skyblue;
    }
    to {
        background-color: yellow;
    }
}

@keyframes wallAnimation {
    from {
        background-color: white;
    }
    to {
        background-color: rgb(99, 99, 99);
        border: 1px solid rgb(99, 99, 99);
    }
}

@media (min-width: 1281px) {
    .node {
        height: 25px;
        width: 25px;
        border: 1px solid grey;
    }
}

@media (min-width: 1550px) {
    .node {
        height: 30px;
        width: 30px;
    }
}
