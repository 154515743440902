header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin-bottom: 20px;
    gap: 5px;
}

select,
button {
    height: fit-content;
    margin: 0;
}
